// Scripts for the simulator options component

// For polyfill purposes, use 'stickybits' on position:sticky elements
import stickybits from 'stickybits';

let text = stickybits('.main-simulator__text');
let background = stickybits('.main-simulator__background');

text.cleanup();
background.cleanup();
