// Scripts for the general feature block component
document.addEventListener(
  "DOMContentLoaded", () => {

    // On load, if the content is less than the limit, hide the expander, but expand the text
    let limit = 240;
    jQuery('.feature-blocks__block').each(function() {
      if( jQuery(this).find('.feature-blocks__content').outerHeight() < limit ){
        jQuery(this).find('.feature-blocks__expander').fadeOut();
        jQuery(this).find('.feature-blocks__content').toggleClass('feature-blocks__content--expanded');
      }
    });

    // When expander is clicked...
    jQuery('.feature-blocks__expander').click(function() {

      // Set the block id
      let block = '#' + jQuery(this).data('for');

      // Toggle content
      jQuery( block ).find('.feature-blocks__content').toggleClass('feature-blocks__content--expanded');

      // Toggle close, expand text
      jQuery( block ).find('.feature-blocks__expander--close').toggleClass('feature-blocks__expander--is-hidden');
      jQuery( block ).find('.feature-blocks__expander--expand').toggleClass('feature-blocks__expander--is-hidden');

      // Toggle icon
      jQuery( block ).find('.feature-blocks__icon').toggleClass('feature-blocks__icon--is-closed');
    });
  }
);
