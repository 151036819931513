// Scripts for the simulator child page template
'use strict'

/**
 * Imports SCSS for Webpack parsing and sets strict mode
 */
import '../../../styles/src/pages/division-simulator.scss';

/**
 * Component imports
 */
// Header CTA
import '../components/promotions/header-cta';
// Video playlist
import '../components/promotions/video-playlist';
// Centered content
import '../components/general/centered-content';
// Feature block
import '../components/general/feature-block';
// Feature repeater
import '../components/general/feature-repeater';
// Article
import '../components/general/article';
// Link block
import '../components/sidebar/link-block';
// Simulator
import '../components/options/simulator';
// Related links
import '../components/options/related-links';
// Testimonial promotion
import '../components/promotions/testimonial';
// Content authorship
import '../components/general/authorship';

/**
 * Page-specific scripting
 */
